import React, { useState, useEffect } from "react";
import footerlogo from '../assets/img/footer-logo.png';
import phoneimg from '../assets/img/footer-call.png';
import mailimg from '../assets/img/mail.png';
import likeimg from '../assets/img/linkedin_img.png';
import chatbot from '../assets/img/final_edu_chatbot_icon_white.png';
import { useSelector } from "react-redux";
import ScrollAnimation from 'react-animate-on-scroll';
import classNames from "classnames";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";

const Footer = () => {
  const { contactData, homeData } = useSelector((state) => state.pageContents);
  return (
    <>
      <div className='footer-sec bg-only'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 footer-width footer-width-one'>
              <ScrollAnimation animateIn="fadeIn">
                <div className='footer-left'>
                  <img src={footerlogo} alt="Appostrophi-logo" />
                  <p>Connecting your institution’s history to a boundless digital future. We have with us to make your digital transformation journey robust.</p>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-md-2 footer-width footer-width-two'>
              <ScrollAnimation animateIn="fadeIn">
                <div className='footer-center'>
                  <h3>Quick Links</h3>
                  <ul>
                    <li> <Link to="/aboutus" ><i class="fa-solid fa-angle-right"></i><span>About Us</span></Link></li>
                    <li><Link to="/services"><i class="fa-solid fa-angle-right"></i><span>Services</span></Link></li>
                    <li><Link to="/project"><i class="fa-solid fa-angle-right"></i><span>Projects</span></Link></li>
                    <li> {
                      homeData?.projects.length &&
                      <Link to={`/casestudies/${homeData.projects[0].slug}`}><i class="fa-solid fa-angle-right"></i><span>Case Studies</span></Link>
                    }

                    </li>
                    <li><Link to="/career"><i class="fa-solid fa-angle-right"></i><span>Careers</span></Link></li>
                    <li><Link to="/contact"><i class="fa-solid fa-angle-right"></i><span>Contact Us</span></Link></li>

                  </ul>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-md-3 footer-width footer-width-three'>
              <ScrollAnimation animateIn="fadeIn">
                <div className='footer-mid-center'>
                  <h3>Get In Touch</h3>
                  <ul>
                    {
                      contactData?.contactpage &&
                      <>
                        {/* <li><a href={`tel:${contactData.contactpage.phone}`}><img src={phoneimg} /><span>
                  {contactData.contactpage.phone}
                </span></a></li> */}
                        <li><a href={`mailto:${contactData.contactpage.email}`}><i class="fa-solid fa-envelope"></i><span>
                          {contactData.contactpage.email}
                        </span></a></li>
                      </>
                    }
                  </ul>
                </div>
              </ScrollAnimation>

            </div>
            <div className='col-md-3 footer-width footer-width-four'>
              <ScrollAnimation animateIn="fadeIn">
                <div className='footer-right'>
                  <h3>Social Media</h3>
                  {
                    contactData?.contactpage &&
                    <ul>
                      <li><a href={contactData.contactpage.linkdin_link} target='_blank'> <img src={likeimg} alt="Linkedin logo" /><span>Linkedin</span></a></li>

                    </ul>
                  }
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <hr className='hr-sec' />

          <div className='footer-bottom'>
            <div className='row'>
              <div className='col-md-7'>

                <div className='footer-bottom-left'>
                  <p>Copyright © All Rights Reserved by Appostrophi</p>
                </div>

              </div>
              <div className='col-md-5'>

                <div className='footer-bottom-right'>
                  <ul>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/terms-condition">Terms & Condition</Link></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className='newchatimgcl'>
            <div  class="up-down-anim">


              <a href="https://eformnewdev.jcboeeformsystem.org/chatbotmocktest" target="_blank">



                <img src={chatbot} style={{ height: '95px'}} />
              </a>
            </div>
          </div >
        </div>

      </div>

    </>
  )
}
export default Footer;